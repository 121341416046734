import React from "react";
import PropTypes from "prop-types";
import Flex from "app/components/Flex";
import { H7 } from "app/components/Typography";
import ClassRow from "app/components/ClassRow";

const ClassRowResults = ({ classData, isClassAdded, addClassToSchedule }) => {
  return (
    <Flex key={classData.id} width="100%" mb={3} borderRadius="2px">
      <ClassRow classData={classData} bg="greys.2" />
      <Flex
        cursor="pointer"
        justifyContent="center"
        alignItems="center"
        width="45px"
        color={isClassAdded ? "white" : "blue"}
        bg={(() => {
          return isClassAdded ? "blue" : "white";
        })()}
        onClick={() => !isClassAdded && addClassToSchedule(classData)}
      >
        <H7>{isClassAdded ? "Added" : "Add"}</H7>
      </Flex>
    </Flex>
  );
};

ClassRowResults.defaultProps = {
  isClassAdded: false,
};

ClassRowResults.propTypes = {
  classData: PropTypes.shape({}).isRequired,
  isClassAdded: PropTypes.bool,
  addClassToSchedule: PropTypes.func.isRequired,
};

export default ClassRowResults;
